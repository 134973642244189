import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePutUserProfileRightsArgs = {
  queryOptions?: UseMutationOptions<
    void,
    Error,
    UsePutUserProfileRightsMutationArgs,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}

export type UpdateUserProfileRightsArgs = {
  newUserRights: AuthenticationRight[]
  oldUserRights: AuthenticationRight[]
  userId: string
  loginId: string
}

export type UsePutUserProfileRightsMutationArgs =
  UpdateUserProfileRightsArgs & {
    userName: string
  }

export const usePutUserProfileRights = (
  args: UsePutUserProfileRightsArgs = {}
) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const { userProfileRepo } = useRepos()
  const { addToast } = useToasts()
  const invalidateQuery = useInvalidateQuery()

  return useMutation(
    ({ userName, ...newUserRightsArgs }: UsePutUserProfileRightsMutationArgs) =>
      userProfileRepo.updateUserRights(newUserRightsArgs),
    {
      onSuccess: (status, { userName }) => {
        addToast(`Rättigheterna för ${userName} är nu uppdaterade`)
        setTimeout(() => invalidateQuery('user-profiles'), 200)

        extendedOnSuccessFunction?.()
      },
      onError: (status, { userName }) => {
        addToast({
          message: `Lyckades inte uppdatera rättigheterna för ${userName}`,
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      ...queryOptions
    }
  )
}

import { useEffect } from 'react'
import { PaymentStatus } from '../domain/enums/PaymentStatus'
import { useGetCustomer } from './queryHooks/useGetCustomer'
import { useGetInvoices } from './queryHooks/useGetInvoices'
import { useGetWordpressBanners } from './queryHooks/useGetWordpressBanners'
import { useBanners } from './useBanners'
import { useIsMobile } from './useIsMobile'
let hasRenderedFrozenBanner = false
let hasRenderedInvoiceBanner = false
let hasRenderedEmailsBanner = false
let hasRenderedInfoEmailBanner = false
export const useSetupBanners = () => {
  const { addBanner } = useBanners()
  const isMobile = useIsMobile()

  /**
   * Skipping suspense on this query as this data isn't crucial for the app
   * and it would be a bad idea to block the UI if the WordPress site had problems.
   */
  useGetWordpressBanners({
    queryOptions: {
      onSuccess: (banners) => {
        banners.forEach((banner) => {
          if (banner.show_on_my_pages) {
            addBanner({
              content: banner.text,
              id: banner.text,
              linkTitle: 'Läs mer',
              type: 'info',
              url: banner?.url
            })
          }
        })
      },
      suspense: false
    }
  })

  /**
   * Make the UI suspend on mobile to prevent CLS during use.
   * Context: The page is pushed by the banners on mobile, but not on desktop
   */
  const { data: customer, isLoading: isCustomerLoading } = useGetCustomer({
    queryOptions: { suspense: Boolean(isMobile) }
  })
  const { data: invoices, isLoading: isInvoicesLoading } = useGetInvoices({
    queryOptions: { suspense: Boolean(isMobile), enabled: !isCustomerLoading }
  })

  useEffect(() => {
    if (!isCustomerLoading && customer?.isFrozen && !hasRenderedFrozenBanner) {
      addBanner({
        content:
          'Ditt konto är spärrat. Vänligen kontakta kundservice på 011-28 00 00 för mer information.',
        icon: {
          name: 'lock',
          primaryColor: 'black',
          secondaryColor: 'red400'
        },
        id: 'account-frozen',
        permanent: true,
        type: 'info',
        noAnimation: Boolean(isMobile)
      })

      // Block all other banners if account is frozen
      hasRenderedFrozenBanner = true
      hasRenderedEmailsBanner = true
      hasRenderedInvoiceBanner = true
      hasRenderedInfoEmailBanner = true
    }

    if (!isInvoicesLoading && !hasRenderedInvoiceBanner) {
      const customerHasOverDueInvoices =
        invoices?.pages
          .at(0)
          ?.payload?.some(
            (invoice) => invoice.getPaymentStatus() === PaymentStatus.Overdue
          ) ?? []

      if (customerHasOverDueInvoices) {
        addBanner({
          content: 'Du har fakturor att betala.',
          icon: {
            name: 'exclamation-triangle',
            primaryColor: 'yellow500',
            secondaryColor: 'red400'
          },
          id: 'unpaid-invoices',
          linkTitle: 'Gå till fakturor',
          type: 'warning',
          url: '/fakturor-kontobesked',
          noAnimation: Boolean(isMobile)
        })
      }

      hasRenderedInvoiceBanner = true
    }

    if (!isCustomerLoading && !hasRenderedInfoEmailBanner) {
      const hasInfoEmail = customer?.emails.some(
        (email) => email.emailTypeId === 'Information'
      )

      if (!hasInfoEmail) {
        addBanner({
          content: 'Din mailadress för informationsutskick måste uppdateras',
          icon: {
            name: 'exclamation-triangle',
            primaryColor: 'yellow500',
            secondaryColor: 'red400'
          },
          id: 'update-info-email',
          linkTitle: 'Dina mailadresser',
          type: 'info',
          url: customer?.isCompany ? '/profil#emails' : '/profil#information',
          noAnimation: Boolean(isMobile)
        })
      }

      hasRenderedInfoEmailBanner = true
    }

    if (!isCustomerLoading && !hasRenderedEmailsBanner) {
      const hasEmail = customer?.emails.some((email) => email.emailAddress)

      if (!hasEmail) {
        addBanner({
          content: 'Din mailadress för utskick behöver uppdateras.',
          icon: null,
          id: 'update-email',
          linkTitle: 'Din profil',
          type: 'info',
          url: '/profil#emails',
          noAnimation: Boolean(isMobile)
        })
      }

      hasRenderedEmailsBanner = true
    }
  }, [isCustomerLoading, isInvoicesLoading])
}

/* eslint-disable no-confusing-arrow */
import React, { useEffect } from 'react'
import { CardType } from '../../domain/enums/CardType'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import { useGetInvoices } from '../../hooks/queryHooks/useGetInvoices'
import { useGetTransactions } from '../../hooks/queryHooks/useGetTransactions'
import { getSevenYearsAgoDate } from '../../utils/getSevenYearsAgoDate'
import { isManualTransaction } from '../../utils/isManualTransaction'
import Link from '../elements/Link'
import Invoices from '../views/Invoices'
import PaymentOptions from '../views/PaymentOptions'
import Uninvoiced from '../views/Uninvoiced'
import UpcomingPayment from '../views/UpcomingPayment'

type Props = {}

const sevenYearsAgoDate = getSevenYearsAgoDate().toISOString()

const PaymentPage: React.FC<Props> = () => {
  const { data: cards } = useGetCards()

  const invoicesQuery = useGetInvoices()

  const latestInvoiceDate: string | undefined = invoicesQuery.data?.pages
    ? invoicesQuery
        .data!.pages.at(0)
        ?.payload.at(0)
        ?.invoiceDate.toISOString() ?? sevenYearsAgoDate
    : undefined

  useEffect(() => {
    /**
     * Fetch two pages initially to make sure we can keep track
     * of the previous invoice date when showing related transactions
     */
    if (
      invoicesQuery.hasNextPage &&
      invoicesQuery.data!.pageParams.length === 1
    ) {
      invoicesQuery.fetchNextPage()
    }
  }, [invoicesQuery.isLoading])

  const uninvoicedTransactionsQuery = useGetTransactions({
    additionalQueryKeys: [latestInvoiceDate],
    fetchQuery: {
      endDate: new Date().toISOString(),
      startDate: latestInvoiceDate,
      cardType: CardType.CreditCard
    },
    queryOptions: {
      enabled: Boolean(invoicesQuery?.data?.pages),
      // Filter out manual transactions as this is not possible through the API at the moment.
      select: (transactionData) => ({
        ...transactionData,
        pages: [
          ...transactionData.pages.map((transactionsPage) => {
            transactionsPage.payload = transactionsPage.payload.filter(
              (transaction) =>
                !isManualTransaction(transaction) && !transaction.invoiceId
            )
            return transactionsPage
          })
        ]
      })
    }
  })

  useEffect(() => {
    const { data, fetchNextPage, hasNextPage } = uninvoicedTransactionsQuery
    const lastPage = data!.pages.at(-1)

    /**
     * Fetch an additional page if the filtered numer of transactions are less than
     * 5 to prevent the situation where the user is presented with an empty page
     */
    if (lastPage && lastPage.payload.length < 4 && hasNextPage) {
      fetchNextPage()
    }
  }, [uninvoicedTransactionsQuery])

  const upcomingInvoices = invoicesQuery
    .data!.pages.map((page) =>
      page.payload.filter((invoice) => invoice.remainingAmount > 0)
    )
    .flat()

  return (
    <>
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <div className="Column Column--JustifyStart Column--Lg6">
            <div className="ContentBox">
              <div className="ContentBox__Content">
                {cards?.tankCards.length === 0 ||
                import.meta.env.VITE_DEBUG_SHOW_NO_CARDS === 'true' ? (
                  <>
                    <h2>{'Våra tankkort'}</h2>
                    <p style={{ marginBottom: 48 }}>
                      {`Vårt stationsnät sträcker sig över hela Sverige, hit och dit och fram och tillbaka. För att få full koll på hur, var och när du tankar, kan det vara ekonomiskt fördelaktigt att skaffa dig något av våra tankkort. Ansökan gör du enkelt online och kortet är såklart gratis.`}
                    </p>
                    <h3>{'Ansök om tankkort'}</h3>
                    <p>
                      {'Du ansöker om tankkort under fliken '}
                      <Link className="SmartCardLink" href="/kort">
                        <b>{'Mina kort'}</b>
                      </Link>
                      {'.'}
                      <p>
                        {
                          'Notera att en kreditupplysning kommer att tas vid ansökan.'
                        }
                      </p>
                    </p>
                  </>
                ) : (
                  <>
                    <h1>{'Tankkort'}</h1>
                    <p style={{ marginBottom: 48 }}>
                      {`Med Qstar Tankkort får du din faktura varje månad, vilket ger dig en överblick över hur mycket du har spenderat under månaden. Brytdatum är alltid natten mellan den 15:e och den 16:e varje månad.`}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="Column Column--JustifyStart Column--Lg0 Column--Md0 Column--Sm12" />
        </div>
      </div>
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          {cards!.tankCards.length > 0 ? (
            <div
              className="Column Column--JustifyStart Column--Lg6"
              style={{ marginBottom: 0 }}
            >
              <h5>{'Att betala'}</h5>
              <UpcomingPayment invoices={upcomingInvoices} />
              <h5>{'Fakturainformation'}</h5>
              <PaymentOptions />
            </div>
          ) : (
            cards!.smartCards.length > 0 && (
              <div className="Column Column--AlignStart Column--Lg6">
                <div
                  style={{
                    backgroundColor: '#FFD200',
                    borderRadius: 10,
                    color: 'black',
                    padding: 20
                  }}
                >
                  {'Du har inga tankkort.'}
                </div>
              </div>
            )
          )}
          {cards!.tankCards.length > 0 && (
            <div className="Column Column--JustifyStart Column--Lg6">
              <h5>{'Ofakturerat belopp'}</h5>
              <Uninvoiced transactionsQuery={uninvoicedTransactionsQuery} />

              <h5>{'Fakturahistorik'}</h5>
              <Invoices invoicesQuery={invoicesQuery} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PaymentPage

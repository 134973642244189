import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SaveState } from '../../domain/enums/SaveState'
import * as ModelState from '../../domain/models/ModelState'
import { useBanners } from '../../hooks/useBanners'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import { getUrlParam } from '../../utils/getUrlParam'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import Link from '../elements/Link'

type Props = {}

type LoginForm = {
  username: string
  password: string
}

const LoginPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { addBanner } = useBanners()

  const [loginState, setLoginState] = useState(ModelState.initialState())
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    username: '',
    password: ''
  })
  useEffect(() => {
    const error = getUrlParam('error')

    if (error) {
      addBanner({
        id: error,
        content: error,
        type: 'warning'
      })
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent): void => {
    const element = event.target as HTMLInputElement
    const name = element.name as keyof LoginForm

    setForm((oldForm) => ({
      ...oldForm,
      [name]: element.value
    }))
  }

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()
    setIsLoading(true)
    ;(async () => {
      const loginValidation = validateLogin()

      if (ModelState.hasErrors(loginValidation)) {
        setLoginState((oldLoginState) =>
          ModelState.setFailed(
            oldLoginState,
            'Kontrollera din inmatning och försök igen.'
          )
        )
        return
      }

      try {
        const success =
          import.meta.env.VITE_DEBUG_PASS_NEED_RESET === 'true'
            ? false
            : await AuthService.loginCustomerNumber(
                form.username,
                form.password
              )

        if (success) {
          window.location.href = '/'
        } else {
          navigate('/glomt-losenord')
        }
      } catch (ex) {
        const error = ex as Error | { message: undefined }
        setLoginState(
          ModelState.setFailed(loginValidation, error?.message ?? `${ex}`)
        )
      }
    })()

    setIsLoading(false)
  }

  const validateLogin = (): ModelState.State => {
    const propertyErrors: { [property: string]: string } = {}

    if (!form.username) {
      propertyErrors.username = 'Kundnummer saknas.'
    }

    if (!form.password) {
      propertyErrors.password = 'Lösenord saknas.'
    }

    return { ...loginState, propertyErrors }
  }

  const forgotPasswordUrl = `/glomt-losenord${
    form.username ? `?customerNo=${form.username}` : ''
  }`

  return (
    <div className="Section Section--Login BgColor--Red400">
      <div className="Row">
        <div className="Row__Inner">
          <div className="Column Column--Md12 Column--Lg7 Column--JustifyStart Login__InfoText">
            <h1 className="Login__Heading">
              {'Mina sidor'}
              <br />
              {'Inloggning'}
            </h1>
            <p className="Login__InfoTextSub">
              {
                'Välkommen till Mina sidor. Här kan du som privatperson och enskild firma logga in med Bank-id. Den nya plattformen är under utveckling och blir ditt nya hem som kund.'
              }
            </p>
            {process.env.NODE_ENV === 'development' &&
              import.meta.env.VITE_VERSION_TEXT && (
                <p className="Login__VersionText">
                  {`Testversion: ${import.meta.env.VITE_VERSION_TEXT}`}
                </p>
              )}
          </div>
          <form
            className="Column Column--Lg4 Column--JustifyStart"
            onSubmit={handleSubmit}
          >
            <div className="Login__Switch">
              <div className="Switch">
                <Link className="Switch__Button" href="/login-bankid">
                  {'BankID'}
                </Link>
                <button
                  className="Switch__Button Switch__Button--Active"
                  type="button"
                >
                  {'Kundnummer'}
                </button>
              </div>
            </div>
            <input
              autoComplete="username"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className="Login__Textfield"
              data-val="true"
              data-val-required="The Username field is required."
              id="Username"
              name="username"
              onChange={handleInputChange}
              placeholder="Kundnummer"
              type="text"
              value={form.username}
            />
            {loginState.propertyErrors.username && (
              <div aria-live="polite" className="Login__Error">
                {loginState.propertyErrors.username}
              </div>
            )}
            <input
              autoComplete="current-password"
              className="Login__Textfield"
              data-val="true"
              data-val-required="The Password field is required."
              id="Password"
              name="password"
              onChange={handleInputChange}
              placeholder="Lösenord"
              type="password"
              value={form.password}
            />
            {loginState.propertyErrors.password && (
              <div aria-live="polite" className="Login__Error">
                {loginState.propertyErrors.password}
              </div>
            )}
            <div className="Login__Bottom">
              {loginState.saveState === SaveState.Failed &&
                loginState.errorMessage && (
                  <div className="Login__Feedback">
                    {loginState.errorMessage}
                  </div>
                )}
              <div className="Login__Actions">
                {!isLoading && (
                  <Button className="Login__LoginButton" type="submit">
                    {'Logga in'}
                  </Button>
                )}
                {isLoading && (
                  <Button className="Login__LoginButton" type="submit">
                    {'Loggar in'}
                    <Icon
                      className="Login__LoginButtonSpinner"
                      fixedWidth
                      name="spinner"
                      spin
                      type="line"
                    />
                  </Button>
                )}
                <Link
                  className="Login__ForgotPassword"
                  href={forgotPasswordUrl}
                >
                  {'Glömt lösenord?'}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage

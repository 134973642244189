import React from 'react'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import stringDate from '../../utils/stringDate'
import Icon from '../elements/Icon'
import Link from '../elements/Link'
import PaymentBadge from '../elements/PaymentBadge'

type Props = {
  invoices: InvoiceModel[]
}

const UpcomingPayment: React.FC<Props> = ({ invoices }) => {
  return (
    <div className="UpcomingPayment">
      <div className="UpcomingPayment__PaymentInfo">
        <div className="UpcomingPayment__Header" />
        {invoices.length > 0 ? (
          <div className="UpcomingPayment__Invoices UpcomingPayment__Area">
            {invoices.map((invoice) => (
              <div
                className="UpcomingPayment__InvoiceRow"
                key={invoice.invoiceID}
              >
                <Link
                  href={
                    `${import.meta.env.VITE_BACKEND_URL}/api/Invoices` +
                    `/Faktura ${invoice.invoiceID}.pdf`
                  }
                  openNewTab
                  styled
                >
                  <Icon
                    className="UpcomingPayment__Icon"
                    name="file-invoice"
                    primaryColor="blue800"
                    secondaryColor="blue400"
                  />
                  <span className="UpcomingPayment__DueDate">
                    {stringDate(invoice.expirationDate)}
                  </span>
                </Link>
                <PaymentBadge
                  className="UpcomingPayment__PaymentBadge"
                  invoice={invoice}
                />
              </div>
            ))}
          </div>
        ) : (
          <div
            className="UpcomingPayment__Invoices UpcomingPayment__Area
          UpcomingPayment__PaidRow"
          >
            <Icon
              className="UpcomingPayment__Icon"
              name="check-circle"
              primaryColor="green300"
              secondaryColor="green500"
            />
            {'Allt är betalt!'}
          </div>
        )}
      </div>
    </div>
  )
}

export default UpcomingPayment

import React, { useEffect } from 'react'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import { getUrlParams } from '../../utils/getUrlParam'
import Loader from '../elements/Loader'

const LogoutPage: React.FC<{}> = () => {
  const logout = () => {
    const { returnPath } = getUrlParams()
    const returnUrl = returnPath
      ? `${window.location.origin}/${encodeURIComponent(returnPath)}`
      : window.location.origin

    AuthService.removeAccessToken()

    window.location.href =
      import.meta.env.VITE_OAUTH_URL +
      '/Account/Logout' +
      `?ReturnUrl=${returnUrl}`
  }

  useEffect(() => logout(), [])

  return <Loader text="Du loggas ut..." />
}

export default LogoutPage

/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import sv from 'date-fns/locale/sv'
import * as React from 'react'
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, { CSSObjectWithLabel } from 'react-select'
import { useGetBankCardTransactions } from '../../hooks/queryHooks/useGetBankCardTransactions'
import { useGetBankCards } from '../../hooks/queryHooks/useGetBankCards'
import Icon from '../elements/Icon'
import Label from '../elements/Label'
import { SmartCardSelectable } from './SmartCard/SpecificCardHistory'
import TransactionTable from './TransactionTable'
registerLocale('sv', sv)

type SpecificCardHistoryProps = {
  selectedCard: BankCardSelectable | null
  setSelectedCard: (card: BankCardSelectable) => void
  setSelectedStartDate: (date: Date) => void
  setSelectedEndDate: (date: Date) => void
  startDate?: Date | null
  endDate?: Date | null
}

export interface BankCardSelectable {
  label: string
  value: string
}

export const SpecificBankCardHistory: React.FC<SpecificCardHistoryProps> = ({
  selectedCard,
  setSelectedCard,
  setSelectedStartDate,
  setSelectedEndDate,
  startDate,
  endDate
}) => {
  const { data: cards } = useGetBankCards()

  const bankCards = cards!.map((card) => {
    return {
      label: card.maskedPan.replaceAll('X', '*'),
      value: card.maskedPan
    }
  })

  const getCorrectEndDate = () => {
    // Check so the date is a valid date object before processing with date functions.
    if ('[object Date]' !== Object.prototype.toString.call(endDate)) {
      return endDate
    }

    const endDateCorrection = new Date(endDate!)
    // Add one day to be able to fetch the start date and forward.
    endDateCorrection.setDate(endDateCorrection.getDate() + 1)

    return endDateCorrection
  }
  /**
   * The startDate you get from the date component always gives you the current time.
   * We reset this time to 00.00.00 before sending it to the query
   *
   * For the endDate we set it plus 1 day at 00.00.00 so we can get a span of one day.
   */
  const balanceHistoryQuery = useGetBankCardTransactions({
    additionalQueryKeys: [selectedCard?.value],
    fetchQuery: {
      magstripe: selectedCard ? selectedCard?.value : undefined,
      endDate: getCorrectEndDate()?.toISOString(),
      startDate: new Date(startDate!.setHours(0, 0, 0)).toISOString()
    },
    queryOptions: {
      enabled: Boolean(selectedCard),
      suspense: false
    }
  })

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
    isLoading
  } = balanceHistoryQuery

  useEffect(() => {
    const lastPage = data?.pages.at(-1)

    /**
     * Fetch an additional page if the filtered numer of transactions are less than
     * 5 to prevent the situation where the user is presented with an empty page
     */
    if (lastPage && lastPage.payload.length < 4 && hasNextPage) {
      fetchNextPage()
    }
  }, [balanceHistoryQuery])

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  const dropdownInputStyle = (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#FFFFFF',
    fontWeight: '600'
  })

  return (
    <>
      <div className="CardsPage__FilterBar">
        <div className="Cardspage--FilterBarDiv">
          <Label text="Datum från:" />
          <div
            className={classNames(
              'CardsPage__FilterItem CardsPage__SearchBarContainer'
            )}
          >
            <DatePicker
              dateFormat={'yyyy-MM-dd'}
              locale="sv"
              selected={startDate}
              onChange={(date) => setSelectedStartDate(date!)}
            />
          </div>
        </div>
        <div className="Cardspage--FilterBarDiv">
          <Label text="Datum till:" />
          <div
            className={classNames(
              'CardsPage__FilterItem CardsPage__SearchBarContainer'
            )}
          >
            <DatePicker
              dateFormat={'yyyy-MM-dd'}
              locale="sv"
              selected={endDate}
              onChange={(date) => {
                setSelectedEndDate(date!)
              }}
            />
          </div>
        </div>
      </div>
      <Row className="SmartCard__Card_Select">
        <Col xs={12}>
          <div className="w-100" id="select_specific_card_col">
            <Select
              className={classNames(
                'Dropdown--Strong mb-2 Dropdown no-border-bottom',
                {
                  SelectCardHistory__Dropdown: true
                }
              )}
              defaultValue={selectedCard}
              hideSelectedOptions={false}
              id="select_newEmail"
              isSearchable
              key="select_specific_card_key"
              noOptionsMessage={() => 'Fann inget kort...'}
              onChange={(newValue) => {
                setSelectedCard(newValue as SmartCardSelectable)
              }}
              options={bankCards}
              placeholder="Välj kort..."
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  border: '1px solid black',
                  borderBottom: state.hasValue ? '2px solid #FFD200' : '',
                  borderRadius: '4px'
                }),
                control: (provided) => ({
                  ...provided,
                  backgroundColor: '#D90000',
                  border: '0px'
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: 'white'
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  backgroundColor: 'white'
                }),
                menu: (provided) => ({
                  ...provided,
                  border: '1px solid black',
                  top: 'inherit'
                  // width: colPosition?.width
                }),
                menuList: (provided) => ({
                  ...provided,
                  backgroundColor: '#D90000',
                  border: '0px'
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#FFEFB9' : 'inherit',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
                  color: state.isFocused ? 'black' : 'white'
                }),
                placeholder: dropdownInputStyle,
                singleValue: dropdownInputStyle
              }}
            />
          </div>
        </Col>
        <Col xs={12}>
          {selectedCard ? (
            <>
              {isFetching && isFetchingNextPage === false ? (
                <div className="BankCardSpinner">
                  <Icon
                    className="UninvoicedBankcard__LoadingSpinner"
                    fixedWidth
                    name="spinner"
                    spin
                    type="line"
                  />
                </div>
              ) : (
                <>
                  {!isFetching && !data ? (
                    <p>{'Något gick fel'}</p>
                  ) : (
                    <>
                      {data!.pages.at(0)?.payload?.length === 0 &&
                      !hasNextPage ? (
                        <p>{'Du har inga transaktioner.'}</p>
                      ) : (
                        <>
                          <TransactionTable
                            hasMore={hasNextPage}
                            isOdd
                            transactionsQuery={data!}
                            isBankCard={true}
                            magstripe={selectedCard?.label}
                          />
                          <div className="Uninvoiced__ActionContainer">
                            {hasNextPage && (
                              <button
                                className="Uninvoiced__LoadMoreButton"
                                onClick={() => fetchNextPage()}
                                type="button"
                              >
                                <Icon
                                  className="Uninvoiced__ButtonIcon"
                                  fixedWidth
                                  name="plus"
                                  type="line"
                                />
                                {isFetchingNextPage
                                  ? 'Laddar fler'
                                  : 'Visa fler'}
                                {isFetchingNextPage && (
                                  <Icon
                                    className="Uninvoiced__LoadingSpinner"
                                    fixedWidth
                                    name="spinner"
                                    spin
                                    type="line"
                                  />
                                )}
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <p>{'Välj ett kort för att se dess kontohändelser'}</p>
            // <AllBankCardsHistory cards={cards!} />
          )}
        </Col>
      </Row>
    </>
  )
}

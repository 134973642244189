/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import * as React from 'react'
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Select, { CSSObjectWithLabel } from 'react-select'
import { useGetCards } from '../../../hooks/queryHooks/useGetCards'
import { useGetSmartCardHistory } from '../../../hooks/queryHooks/useGetSmartCardHistory'
import Icon from '../../elements/Icon'
import TransactionTable from '../TransactionTable'

type SpecificCardHistoryProps = {
  selectedCard: SmartCardSelectable | null
  setSelectedCard: (card: SmartCardSelectable) => void
}

export interface SmartCardSelectable {
  label: string
  value: string
}

export const SpecificCardHistory: React.FC<SpecificCardHistoryProps> = ({
  selectedCard,
  setSelectedCard
}) => {
  const { data: cards } = useGetCards()
  const smartCards = cards!.smartCards.map((card) => {
    return { label: card.cardReference1, value: card.cardNo.toString() }
  })

  const balanceHistoryQuery = useGetSmartCardHistory({
    additionalQueryKeys: [selectedCard?.label, selectedCard?.value],
    fetchQuery: {
      cardNo: selectedCard ? parseInt(selectedCard?.value) : undefined
    },
    queryOptions: {
      enabled: Boolean(selectedCard),
      suspense: false
    }
  })
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    balanceHistoryQuery

  useEffect(() => {
    const lastPage = data?.pages.at(-1)

    /**
     * Fetch an additional page if the filtered numer of transactions are less than
     * 5 to prevent the situation where the user is presented with an empty page
     */
    if (lastPage && lastPage.payload.length < 4 && hasNextPage) {
      fetchNextPage()
    }
  }, [balanceHistoryQuery])

  const dropdownInputStyle = (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#FFFFFF',
    fontWeight: '600'
  })

  return (
    <Row className="SmartCard__Card_Select">
      <Col xs={12}>
        <div className="w-100" id="select_specific_card_col">
          <Select
            className={classNames('Dropdown--Strong mb-2', {
              SelectCardHistory__Dropdown: true
            })}
            defaultValue={selectedCard}
            hideSelectedOptions={false}
            id="select_newEmail"
            isSearchable
            key="select_specific_card_key"
            noOptionsMessage={() => 'Fann inget kort...'}
            onChange={(newValue) =>
              setSelectedCard(newValue as SmartCardSelectable)
            }
            options={smartCards}
            placeholder="Välj kort..."
            styles={{
              container: (provided, state) => ({
                ...provided,
                border: '1px solid black',
                borderBottom: state.hasValue ? '2px solid #FFD200' : '',
                borderRadius: '4px'
              }),
              control: (provided) => ({
                ...provided,
                backgroundColor: '#D90000',
                border: '0px'
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: 'white'
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: 'white'
              }),
              menu: (provided) => ({
                ...provided,
                border: '1px solid black',
                top: 'inherit'
                // width: colPosition?.width
              }),
              menuList: (provided) => ({
                ...provided,
                backgroundColor: '#D90000',
                border: '0px'
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#FFEFB9' : 'inherit',
                borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
                color: state.isFocused ? 'black' : 'white'
              }),
              placeholder: dropdownInputStyle,
              singleValue: dropdownInputStyle
            }}
          />
        </div>
      </Col>
      <Col xs={12}>
        {selectedCard && data ? (
          <>
            <TransactionTable
              hasMore={hasNextPage}
              isOdd
              transactionsQuery={data}
              isBankCard={false}
			  magstripe=''
            />
            <div className="Uninvoiced__ActionContainer">
              {hasNextPage && (
                <button
                  className="Uninvoiced__LoadMoreButton"
                  onClick={() => fetchNextPage()}
                  type="button"
                >
                  <Icon
                    className="Uninvoiced__ButtonIcon"
                    fixedWidth
                    name="plus"
                    type="line"
                  />
                  {isFetchingNextPage ? 'Laddar fler' : 'Visa fler'}
                  {isFetchingNextPage && (
                    <Icon
                      className="Uninvoiced__LoadingSpinner"
                      fixedWidth
                      name="spinner"
                      spin
                      type="line"
                    />
                  )}
                </button>
              )}
            </div>
          </>
        ) : (
          <p>{'Välj ett kort för att se dess kontohändelser'}</p>
        )}
      </Col>
    </Row>
  )
}

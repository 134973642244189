/* eslint-disable react/no-multi-comp */
/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import classNames from 'classnames'
import React from 'react'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { EmailType } from '../../../integrations/allkort/v1/enums/EmailType'

import Icon from '../../elements/Icon'
import RightsButton from '../../elements/RightsButton'
import CustomerProfileEmailsHead from '../CustomerProfileEmailsHead'
import { mapEmailsToEmailEdit } from './CustomerProfileEmails'

type Props = { setEditMode: (newValue: boolean) => void }

export const CustomerProfileEmailsView: React.FC<Props> = ({ setEditMode }) => {
  const { data: customer } = useGetCustomer()

  const emails = mapEmailsToEmailEdit(customer!.emails)

  const handleEdit = () => {
    setEditMode(true)
  }

  const EmailTypeValues = Object.values(EmailType)

  if (!emails) {
    return (
      <div>
        <h4>{'Mailadresser'}</h4>
      </div>
    )
  }
  return (
    <>
      {!emails ? (
        <p>
          <i>{'Du har inga mailadresser inlagda.'}</i>
        </p>
      ) : (
        <table className="ProfileEmailTable">
          <CustomerProfileEmailsHead />
          <tbody>
            {emails.map((item, index) => {
              return (
                <tr key={index}>
                  <>
                    <th>
                      <p>{item.address}</p>
                    </th>
                    {EmailTypeValues.map((key) => {
                      if (
                        key === EmailType.InvoiceStatement &&
                        customer!.invoiceMailingDeliveryType ===
                          'PaperMailing' &&
                        customer!.statementMailingDeliveryType ===
                          'PaperMailing'
                      ) {
                        return null
                      }
                      return (
                        <th key={key}>
                          {item.types.includes(EmailType[key]) ? (
                            <Icon
                              className="ProfileEmails__CheckBoxIcon"
                              name="check-circle"
                              primaryColor="green300"
                              secondaryColor="green500"
                            />
                          ) : (
                            <Icon
                              className="ProfileEmails__CheckBoxIcon"
                              name="circle"
                              primaryColor="blue800"
                              secondaryColor="blue800"
                            />
                          )}
                        </th>
                      )
                    })}
                  </>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      <RightsButton
        className={classNames('PaymentOptions__Badge', {
          'mt-5': emails.length > 0
        })}
        onClick={handleEdit}
        userRights="EmailWrite"
      >
        {'Redigera mailaddresser'}
      </RightsButton>
    </>
  )
}

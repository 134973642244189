import React, { useState } from 'react'
import { usePutUserProfileInfo } from '../../hooks/queryHooks/usePutUserProfile'
import { usePutUserProfileRights } from '../../hooks/queryHooks/usePutUserProfileRights'
import { InputStateObject, useForm } from '../../hooks/useForm'
import { AuthenticationRightsList } from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import { AuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import { UserProfileUpdate } from '../../integrations/allkort/v1/schemas/UserProfileUpdate'
import Button from '../elements/Button'
import Input from '../elements/Input'
import { ConfirmationModalData } from './ConfirmationModal'
import RightsSelection from './RightsSelection'
import { UserProfileSummary } from './UserProfileSummary'

interface UserProfileEditModalProps {
  selectedUser: UserProfile
  closeModal: () => void
  authenticationRights: AuthenticationRightsList
  setConfirmationModal: (modalState: ConfirmationModalData | null) => void
}

export const UserProfileEditModal: React.FC<UserProfileEditModalProps> = ({
  selectedUser: {
    updatedDate,
    createdDate,
    userProfileId,
    logins,
    ...selectedUserInfo
  },
  authenticationRights,
  setConfirmationModal,
  closeModal
}) => {
  const updateUserRightsMutation = usePutUserProfileRights({
    queryOptions: { onSettled: () => closeModal() }
  })

  const updateUserInfoMutation = usePutUserProfileInfo({
    queryOptions: { onSettled: () => closeModal() }
  })

  const {
    inputs,
    handleInputChange,
    isValid,
    getValues,
    validateInput,
    hasChanged: formHasChanged
  } = useForm<InputStateObject<UserProfileUpdate>>({
    firstName: {
      value: selectedUserInfo.firstName,
      isError: false,
      required: true,
      validationFunction: (input: string) => input?.length < 40,
      errorMessage: 'Förnamnet måste vara mellan 1-40 tecken långt'
    },
    lastName: {
      value: selectedUserInfo.lastName,
      required: true,
      isError: false,
      validationFunction: (input: string) => input?.length < 40,
      errorMessage: 'Efternamnet måste vara mellan 1-40 tecken långt'
    },
    email: {
      value: selectedUserInfo.email,
      isError: false,
      required: true,
      validationFunction: (input: string) =>
        input.length < 100 && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input),
      errorMessage: 'Vänligen ange en giltig mailadress'
    },
    phoneNo: {
      value: selectedUserInfo.phoneNo,
      required: true,
      validationFunction: (input: string) => /^\+46\d{6,28}$/.test(input),
      errorMessage:
        'Telefonnummer måste börja med +46 och vara mellan 8-30 tecken långt',
      isError: false
    }
  })

  const selectedUserLogin = logins.find((login) => login.loginType === 'BankID')
  const selectedUserLoginId = selectedUserLogin?.loginId as string
  const oldUserRights = selectedUserLogin?.loginAuthenticationRoles.map(
    (right) => right.authenticationRole
  ) as AuthenticationRight[]

  const [rightsHaveChanged, setRightsHaveChanged] = useState(false)
  const [selectedUserRights, setSelectedUserRights] =
    useState<AuthenticationRight[]>(oldUserRights)

  const triggerConfimationModal = () => {
    setConfirmationModal({
      isOpen: true,
      title: `Är du säker på att du vill uppdatera användaren?`,
      content: (
        <UserProfileSummary
          authenticationRights={authenticationRights}
          userProfile={{
            ...getValues<UserProfileUpdate>(inputs),
            rights: selectedUserRights
          }}
        />
      ),
      confirmText: `Uppdatera ${inputs.firstName.value} ${inputs.lastName.value}`,
      rejectText: 'Tillbaka',
      rejectCallback: () => setConfirmationModal(null),
      confirmCallback: () => {
        if (formHasChanged) {
          updateUserInfoMutation.mutate({
            updatedUserData: getValues(inputs),
            userId: userProfileId
          })
        }

        if (rightsHaveChanged) {
          updateUserRightsMutation.mutate({
            newUserRights: selectedUserRights,
            oldUserRights,
            userId: userProfileId,
            loginId: selectedUserLoginId,
            userName: `${selectedUserInfo.firstName} ${selectedUserInfo.lastName}`
          })
        }
        setConfirmationModal(null)
      }
    })
  }

  const handleRightsChange = (newRights: AuthenticationRight[]) => {
    setRightsHaveChanged(true)
    setSelectedUserRights(newRights)
  }

  return (
    <>
      <div className="Modal__Section FormModal__Row pb-2">
        <h5>{`Redigera ${selectedUserInfo.firstName} ${selectedUserInfo.lastName}`}</h5>
      </div>
      <div className="Modal__Section FormModal__Row FormModal__Row--inputs">
        <Input
          error={
            inputs.firstName?.isError
              ? inputs.firstName.errorMessage
              : undefined
          }
          label="Förnamn *"
          name="firstName"
          onBlur={() => validateInput('firstName')}
          onChange={handleInputChange}
          required
          value={inputs.firstName.value}
        />
        <Input
          error={
            inputs.lastName?.isError ? inputs.lastName.errorMessage : undefined
          }
          label="Efternamn *"
          name="lastName"
          onBlur={() => validateInput('lastName')}
          onChange={handleInputChange}
          required
          value={inputs.lastName.value}
        />
        <Input
          error={inputs.email?.isError ? inputs.email.errorMessage : undefined}
          label="E-mail *"
          name="email"
          onBlur={() => validateInput('email')}
          onChange={handleInputChange}
          required
          value={inputs.email.value}
        />
        <Input
          error={
            inputs.phoneNo?.isError ? inputs.phoneNo.errorMessage : undefined
          }
          label="Telefon *"
          name="phoneNo"
          onBlur={() => validateInput('phoneNo')}
          onChange={handleInputChange}
          required
          value={inputs.phoneNo.value}
        />
        {selectedUserRights && (
          <RightsSelection
            currentRights={selectedUserRights}
            onChange={handleRightsChange}
          />
        )}
      </div>
      <div className="FormModal__Actions Modal__Section">
        <Button
          className="FormModal__Button FormModal__Button--Cancel"
          onClick={() => closeModal()}
        >
          {'Stäng'}
        </Button>
        <Button
          className="FormModal__Button"
          disabled={
            (formHasChanged && !isValid) ||
            (!formHasChanged && !rightsHaveChanged)
          }
          isLoading={
            updateUserInfoMutation.isLoading ||
            updateUserRightsMutation.isLoading
          }
          loadingText="Uppdaterar användare"
          onClick={() => triggerConfimationModal()}
        >
          {`Uppdatera ${selectedUserInfo.firstName} ${selectedUserInfo.lastName}`}
        </Button>
      </div>
    </>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import { UserProfileCreate } from '../../integrations/allkort/v1/schemas/UserProfileCreate'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePostUserProfileArgs = {
  queryOptions?: UseMutationOptions<
    UserProfile,
    Error,
    PostUserProfileMutationArgs,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
export type PostUserProfileMutationArgs = UserProfileCreate

export const usePostUserProfile = (args: UsePostUserProfileArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { userProfileRepo } = useRepos()

  const mutation = useMutation(
    (userData: PostUserProfileMutationArgs) =>
      userProfileRepo.createUserProfile(userData),
    {
      onError: (data, userData) => {
        addToast({
          message:
            `Lyckades inte skapa ${userData.firstName} ${userData.lastName}. ` +
            'Försök igen senare eller kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (data, userData) => {
        addToast(`${userData.firstName} ${userData.lastName} är nu skapad`)
        invalidateQuery('user-profiles')
        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}

import { PagedResult } from '../../../../domain/models/PagedResult'
import { UpdateUserProfileRightsArgs } from '../../../../hooks/queryHooks/usePutUserProfileRights'
import { getArrayDifference } from '../../../../utils/getArrayDifference'
import {
  AuthenticationRightsList,
  UserProfileProxy
} from '../proxies/UserProfileProxy'
import { LoginAuthenticationRoleCreate } from '../schemas/LoginAuthenticationRoleCreate'
import { UserProfile } from '../schemas/UserProfile'
import { UserProfileCreate } from '../schemas/UserProfileCreate'
import { UserProfileUpdate } from '../schemas/UserProfileUpdate'

export type UserProfileSortingParam = 'FirstName' | 'LastName' | 'Email'
export type UserProfileSortingOrder = 'des' | 'asc'
export type UserProfileSortingString =
  `${UserProfileSortingParam}+${UserProfileSortingOrder}`

export type UserProfileQuery = {
  PageIndex?: number
  PageSize?: number
  includeLogins?: boolean
  sortingParameters?: UserProfileSortingString
}

export type UpdateUserProfileInfoArgs = {
  userId: string
  updatedUserData: UserProfileUpdate
}

export class UserProfileRepository {
  private apiToken: string
  private proxy: UserProfileProxy

  constructor(apiToken: string) {
    this.apiToken = apiToken
    this.proxy = new UserProfileProxy(this.apiToken)
  }

  async getAuthenticationRights(): Promise<AuthenticationRightsList> {
    const authenticationRightsList = await this.proxy.getAuthenticationRights()

    return authenticationRightsList
  }

  async getCurrentUser(): Promise<UserProfile> {
    const userProfile = await this.proxy.getCurrentUser()

    return userProfile
  }

  async getUserProfilesPaged(query: UserProfileQuery) {
    const userProfiles = await this.proxy.getUserProfilesPaged(query)

    return userProfiles as PagedResult<UserProfile>
  }

  async getUserProfiles(): Promise<UserProfile[]> {
    const userProfiles = await this.proxy.getUserProfiles()

    return userProfiles
  }

  createdUserID: string | null = null
  async createUserProfile(
    newUserObject: UserProfileCreate
  ): Promise<UserProfile> {
    try {
      const newUserProfile = await this.proxy.createUser(newUserObject)
      this.createdUserID = newUserProfile.userProfileId
      const newUserLogin = await this.proxy.createLogin(
        newUserProfile.userProfileId,
        {
          isActive: true,
          loginCredentials: newUserObject.personalNumber,
          userIdentifier: newUserObject.personalNumber
        }
      )

      const userRights: LoginAuthenticationRoleCreate =
        newUserObject.rights.map((right) => {
          return { loginAuthenticationRole: right }
        })

      await this.proxy.createAuthenticationRole(
        newUserProfile.userProfileId,
        newUserLogin.loginId,
        userRights
      )

      return newUserProfile
    } catch (err) {
      // Saving the ID as a temp var in scope so that it's empty if the delete call fails.
      const tempID = this.createdUserID as string
      this.createdUserID = null
      await this.proxy.deleteUser(tempID)

      // @ts-ignore
      throw new Error(err.message)
    }
  }

  async updateUserProfileInfo(
    args: UpdateUserProfileInfoArgs
  ): Promise<UserProfile> {
    const updatedUserProfile = await this.proxy.updateUser(args)

    return updatedUserProfile
  }

  async updateUserRights({
    userId,
    loginId,
    newUserRights,
    oldUserRights
  }: UpdateUserProfileRightsArgs) {
    const rights = await this.getAuthenticationRights()
    const rightsToRemove = getArrayDifference(oldUserRights, newUserRights)
    const rightsToAdd = getArrayDifference(newUserRights, oldUserRights)

    if (rightsToRemove.length > 0) {
      this.proxy.deleteAuthenticationRole(
        userId,
        loginId,
        rightsToRemove.map((rightName) => {
          return rights.find((right) => right.claimName === rightName)
            ?.authenticationRoleId as number
        })
      )
    }

    if (rightsToAdd.length > 0) {
      this.proxy.createAuthenticationRole(
        userId,
        loginId,
        rightsToAdd.map((right) => {
          return { loginAuthenticationRole: right }
        }) as LoginAuthenticationRoleCreate
      )
    }
  }

  async deleteUser(userId: string) {
    const response = await this.proxy.deleteUser(userId)
    return response
  }
}

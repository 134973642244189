import { atom, useAtom } from 'jotai'
import {
  allAuthenticationRights,
  AuthenticationRight
} from '../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import { getCurrentUserRights } from '../utils/getCurrentUserRights'

export const userRightsAtom = atom(getCurrentUserRights())
userRightsAtom.debugLabel = 'rightsAtom'

export const previousRightsAtom = atom(
  getCurrentUserRights() === 'Superuser'
    ? [...allAuthenticationRights]
    : (getCurrentUserRights() as AuthenticationRight[])
)
previousRightsAtom.debugLabel = 'previousRightsAtom'

export const useUserRights = () => {
  const [userRights] = useAtom(userRightsAtom)

  const userHasRight = (right: AuthenticationRight | AuthenticationRight[]) => {
    if (userRights === 'Superuser') {
      return true
    }

    let hasRight = false

    if (typeof right === 'string') {
      hasRight = userRights.includes(right)
    } else {
      const missingRights = right.filter((r) => !userRights.includes(r))

      if (missingRights.length === 0) {
        hasRight = true
      }
    }

    return hasRight
  }

  return { userRights, userHasRight, isSuperUser: userRights === 'Superuser' }
}

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import isEmpty from 'validator/es/lib/isEmpty'
import { useGetAgreements } from '../../../../hooks/queryHooks/useGetAgreements'
import { useGetCustomer } from '../../../../hooks/queryHooks/useGetCustomer'
import { usePostCustomerServiceTask } from '../../../../hooks/queryHooks/usePostCustomerServiceTask'
import { usePutCustomer } from '../../../../hooks/queryHooks/usePutCustomer'
import { InputStateObject, useForm } from '../../../../hooks/useForm'
import useIsBankIdUser from '../../../../hooks/useIsBankIdUser'
import { useToasts } from '../../../../hooks/useToasts'
import { Email } from '../../../../integrations/allkort/v1/schemas/Email'
import { isAutogiroEnabled } from '../../../../utils/isAutogiroEnabled'
import Checkbox from '../../../elements/Checkbox'
import Icon from '../../../elements/Icon'
import Input from '../../../elements/Input'
import PaymentAutogiroGroup from '../../../elements/PaymentAutogiroGroup'
import ProfileSettingsModal from '../ProfileSettingsModal'

const AUTOGIRO_MESSAGE: React.ReactNode = (
  <>
    {'Autogirot dras den'}
    <b>{' 28:e '}</b>
    {`varje månad. För att autogiro ska fungera måste ansvarig `}
    {`kontoinnehavare och ansvarig bankkontoinnehavare vara samma person.`}
  </>
)

const AUTOGIRO_DELAY_NOTICE = `Byte till eller från autogiro kan ta upp till 10 dagar.`
const AUTOGIRO_PENDING_NOTICE = `En ansökan om autogiro är under behandling.`

const AUTOGIRO_ENABLED_NOTICE = `Du har för närvarande autogiro kopplat.`
const AUTOGIRO_DISABLED_NOTICE = `Du har för närvarande inte autogiro kopplat.`

type Props = {
  closeModal: () => void
}

export type AutogiroForm = {
  autogiroBank: string
  autogiroClearingNo: string
  autogiroAccountNo: string
}

const changeThresholdDays = 10
const minimumChangeDate = (() => {
  const date = new Date()
  date.setDate(date.getDate() - changeThresholdDays)
  return date
})()

export const AutogiroModal: React.FC<Props> = ({ closeModal }) => {
  const isBankIdUser = useIsBankIdUser()
  const { data: agreements } = useGetAgreements({
    queryOptions: { suspense: false }
  })
  const { data: customer } = useGetCustomer()
  const { addToast } = useToasts()

  const [newEmail, setNewEmail] = useState(
    customer!.emails.find((email) => email.emailTypeId === 'InvoiceStatement')
      ?.emailAddress ?? ''
  )
  const [emailError, setEmailError] = useState('')

  const [changeEmail, setChangeEmail] = useState(false)
  const InvoiceMail = customer!.emails.find(
    (element) => element.emailTypeId === 'InvoiceStatement'
  )?.emailAddress

  const hasInvoiceMail = customer?.emails.some(
    (email) => email.emailTypeId === 'InvoiceStatement'
  )

  const handleChecked = (checked: boolean) => {
    setChangeEmail(checked)
  }

  const currentPaymentMethod = customer!.invoiceMailingDeliveryType ?? null

  const updateCustomerMutation = usePutCustomer()
  const sendCustomerServiceMessageMutation = usePostCustomerServiceTask({
    queryOptions: {
      onSuccess: () => {
        updateCustomerMutation.mutate({
          isAutogiroChangeRequestedByCustomer: true
        })
        addToast('Din autogiroförfrågan är nu skickad')
      },
      onError: () =>
        addToast({
          message: 'Lyckades inte skicka autogiroförfrågan',
          isError: true
        })
    }
  })

  const newAutogiroForm = useForm<InputStateObject<AutogiroForm>>({
    autogiroBank: {
      value: '',
      isError: false,
      required: true,
      validationFunction: (input) => !isEmpty(input),
      errorMessage: 'Obligatoriskt fält'
    },
    autogiroClearingNo: {
      value: customer!.autogiroClearingNo ?? '',
      validationFunction: (input) =>
        !isEmpty(input) && (input.length === 4 || input.length === 5),
      errorMessage: 'Ett clearingnummer är mellan 4 och 5 siffor',
      isError: false,
      required: true
    },
    autogiroAccountNo: {
      value: customer!.autogiroAccountNo ?? '',
      isError: false,
      required: true,
      validationFunction: (input) =>
        !isEmpty(input) && input.length > 4 && input.length < 12,
      errorMessage: 'Ett kontonummer är mellan 5 och 11'
    }
  })

  const [autogiroAgreementApproved, setAutogiroAgreementApproved] =
    useState(false)

  const createDelayNotice = () => {
    return (
      <p className="PaymentMethodModal__Notice PaymentMethodModal__Notice--AutogiroDelay">
        <Icon
          className="Card__Icon"
          name="clock"
          primaryColor="black"
          secondaryColor="yellow500"
        />
        {AUTOGIRO_DELAY_NOTICE}
      </p>
    )
  }

  const handleInputChange = (email: string) => {
    if (!isEmail(email)) {
      setEmailError('Mailadressen ser inte rätt ut.')
    } else if (newEmail === InvoiceMail) {
      setEmailError('Mailadressen är oförändrad')
    } else {
      setEmailError('')
    }

    setNewEmail(email)
  }

  const handleSave = () => {
    let message = ''
    if (isAutogiroEnabled(customer!)) {
      message = 'Ansökan om uppsägning av Autogiro'
    } else {
      const { autogiroAccountNo, autogiroBank, autogiroClearingNo } =
        newAutogiroForm.getValues(newAutogiroForm.inputs)
      message = `Ansökan om Autogiro
      Clearingnummer: ${autogiroClearingNo}
      Kontonummer: ${autogiroAccountNo}
      Bank: ${autogiroBank}`
    }

    if (changeEmail) {
      const newEmails: Email[] = [
        ...customer!.emails.filter(
          (email) => email.emailTypeId !== 'InvoiceStatement'
        ),
        {
          emailAddress: newEmail,
          emailId: 0,
          emailTypeId: 'InvoiceStatement',
          sortOrder: 0
        }
      ]

      updateCustomerMutation.mutate({
        invoiceMailingDeliveryType: 'Email',
        emails: newEmails
      })
    }

    sendCustomerServiceMessageMutation.mutate(message)
  }

  const getSaveButtonState = (isPending: boolean, isEnabled: boolean) => {
    // A change is currently pending, no action to perform
    if (isPending) return 'hidden'
    // The only actions is to cancel Autogiro, so always enable the button
    if (isEnabled) return 'enabled'
    // Check if Autogiro changed:

    if (changeEmail) {
      return newAutogiroForm.isValid &&
        (isBankIdUser ? autogiroAgreementApproved : true) &&
        emailError === ''
        ? 'enabled'
        : 'disabled'
    }
    return newAutogiroForm.isValid &&
      (isBankIdUser ? autogiroAgreementApproved : true)
      ? 'enabled'
      : 'disabled'
  }

  const isPendingOrWithinThreshold = () => {
    const enabled = customer!.isAutogiroChangeRequestedByCustomer
    const changedDate = customer!.autogiroChangeRequestedByCustomerDate

    if (enabled) {
      // Pending is indicated by flag, no need to check the date
      return true
    }

    if (changedDate === null) {
      // No change date was passed. Trust the pending flag.
      return enabled
    }

    // Treat as pending if changed to false less than `changeThresholdDays` ago
    return new Date(changedDate) > minimumChangeDate
  }

  const isPending = isPendingOrWithinThreshold()

  const saveButtonState = getSaveButtonState(
    isPending,
    isAutogiroEnabled(customer!)
  )

  return (
    <ProfileSettingsModal
      className="ProfileSettingsModal"
      closeButtonText={isPending ? 'Ok' : 'Avbryt'}
      onBack={() => {}}
      onCancel={closeModal}
      onConfirm={() => {}}
      onSave={handleSave}
      saveButtonState={saveButtonState}
      saveButtonText={
        isAutogiroEnabled(customer!)
          ? 'Ansök om uppsägning'
          : 'Ansök om Autogiro'
      }
      saved={false}
    >
      <div
        className="Modal__Section ProfileSettings__Row"
        style={{ paddingBottom: 0 }}
      >
        <h5>{'Autogiro'}</h5>
      </div>
      {isPending ? (
        <div className="Modal__Section ProfileSettings__Row">
          <div>
            <div className="ProfileSettings__AutogiroStatus">
              {isAutogiroEnabled(customer!) ? (
                <p>{AUTOGIRO_ENABLED_NOTICE}</p>
              ) : (
                <p>{AUTOGIRO_DISABLED_NOTICE}</p>
              )}
            </div>
            <p className="PaymentMethodModal__Notice PaymentMethodModal__Notice--AutogiroRequest">
              <Icon
                className="Card__Icon"
                name="clock"
                primaryColor="white"
                secondaryColor="black"
              />
              {AUTOGIRO_PENDING_NOTICE}
            </p>
          </div>
        </div>
      ) : isAutogiroEnabled(customer!) ? (
        <div className="Modal__Section ProfileSettings__Row">
          <div>
            <p className="ProfileSettings__AutogiroStatus">
              {AUTOGIRO_ENABLED_NOTICE}
            </p>
            {createDelayNotice()}
          </div>
        </div>
      ) : (
        <>
          <div className="Modal__Section ProfileSettings__Row">
            <div className="ProfileSettings__Row AutoGiro__Row">
              <div
                className="ProfileSettings__Column"
                style={{ overflow: 'hidden' }}
              >
                <PaymentAutogiroGroup
                  autogiroForm={newAutogiroForm}
                  closeModal={closeModal}
                />
                {currentPaymentMethod === 'PaperMailing' && (
                  <>
                    <div className="ProfileSettings__Warningbox">
                      <Icon
                        className="Warning__Icon"
                        name="exclamation-triangle"
                        primaryColor="yellow500"
                        secondaryColor="red400"
                      />
                      <div>
                        {'Du har pappersfaktura. Kostnaden är 25 kr/månad'}
                        <br />
                        <Checkbox
                          checked={changeEmail}
                          className="autogiroCheckbox"
                          onChange={handleChecked}
                          text="Byt till mailfaktura (kostnadsfritt)"
                        />
                      </div>
                    </div>
                    <br />
                    {changeEmail && (
                      <>
                        <Input
                          className="emailInput"
                          fullWidth
                          label="Mail"
                          name="autogiroAccountNo"
                          onChange={(e) =>
                            handleInputChange(e.currentTarget.value)
                          }
                          type="text"
                          value={newEmail}
                        />
                        {emailError && (
                          <div className="PaymentMethod__ErrorMessage">
                            {emailError}
                          </div>
                        )}
                        {hasInvoiceMail && (
                          <small>
                            {'Din nuvarande fakturamail är '}
                            <b> {InvoiceMail}</b>
                          </small>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="ProfileSettings__Column">
                <div className="ProfileSettings__Box__autogiro">
                  {AUTOGIRO_MESSAGE}
                </div>
              </div>
            </div>
          </div>
          {isBankIdUser && (
            <div className="Modal__Section">
              {agreements?.map(
                (agreement) =>
                  agreement.name.includes('autogiro') && (
                    <Checkbox
                      checked={autogiroAgreementApproved ?? false}
                      className="ProfileSettings__Agreement"
                      key={agreement.agreementId}
                      onChange={(checked) =>
                        setAutogiroAgreementApproved(checked)
                      }
                      // text={agreement.summary}
                      text={
                        'Jag medger att uttag får göras från angivet bankkonto på begäran av Qstar Försäljning AB för överföring till dem. Jag har tagit del av de regler för Autogiro, som finns i <a href="https://qstar.se/tankkort/avtalsvillkor" title="https://www.qstar.se/tankkort/avtalsvillkor">kontobestämmelserna</a> för Qstar Bilisten Pump, och godkänner dessa.'
                      }
                    />
                  )
              )}
              <br />
              {createDelayNotice()}
            </div>
          )}
        </>
      )}
    </ProfileSettingsModal>
  )
}

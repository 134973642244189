import React, { useState } from 'react'
import { usePostUserProfile } from '../../hooks/queryHooks/usePostUserProfile'
import { InputStateObject, useForm } from '../../hooks/useForm'
import { useToasts } from '../../hooks/useToasts'
import { AuthenticationRightsList } from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import {
  AuthenticationRight,
  allReadAuthenticationRights
} from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import { UserProfileCreateForm } from '../../integrations/allkort/v1/schemas/UserProfileCreate'
import Button from '../elements/Button'
import Input from '../elements/Input'
import { ConfirmationModalData } from './ConfirmationModal'
import RightsSelection from './RightsSelection'
import { UserProfileSummary } from './UserProfileSummary'

interface UserProfileCreateModalProps {
  closeModal: () => void
  authenticationRights: AuthenticationRightsList
  setConfirmationModal: (modalState: ConfirmationModalData | null) => void
}

export const UserProfileCreateModal: React.FC<UserProfileCreateModalProps> = ({
  closeModal,
  setConfirmationModal,
  authenticationRights
}) => {
  const { addToast } = useToasts()

  const [userRights, setUserRights] = useState<AuthenticationRight[]>([
    ...allReadAuthenticationRights
  ])

  const createUserMutation = usePostUserProfile({
    extendedOnSuccessFunction: closeModal,
    queryOptions: {
      onError: (err, newUserObject) => {
        if (err.message.includes('UserIdentifier')) {
          setConfirmationModal({
            isOpen: true,
            title: `En användare med personnummer ${newUserObject.personalNumber} existrerar redan`,
            confirmText: `Tillbaka`,
            rejectText: 'Avbryt',
            rejectCallback: () => {
              setConfirmationModal(null)
              closeModal()
            },
            confirmCallback: () => {
              setConfirmationModal(null)
            }
          })
        } else {
          setConfirmationModal(null)
          closeModal()
          addToast({
            message:
              'Ett oväntat fel inträffade. Användaren kunde inte skapas.',
            isError: true
          })
        }
      }
    }
  })

  const { inputs, handleInputChange, isValid, getValues, validateInput } =
    useForm<InputStateObject<UserProfileCreateForm>>({
      personalNumber: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input: string) => /^\d{12}$/.test(input),
        errorMessage:
          'Personnummer måste vara 12 tecken utan mellanslag, bindestreck eller några andra tecken'
      },
      firstName: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input: string) => input?.length < 40,
        errorMessage: 'Förnamnet måste vara mellan 1-40 tecken långt'
      },
      lastName: {
        value: '',
        required: true,
        isError: false,
        validationFunction: (input: string) => input?.length < 40,
        errorMessage: 'Efternamnet måste vara mellan 1-40 tecken långt'
      },
      email: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input: string) =>
          input.length < 100 && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input),
        errorMessage: 'Vänligen ange en giltig mailadress'
      },
      phoneNo: {
        value: '',
        required: true,
        validationFunction: (input: string) => /^\+46\d{6,28}$/.test(input),
        errorMessage:
          'Telefonnummer måste börja med +46 och vara mellan 8-30 tecken långt',
        isError: false
      }
    })

  const triggerConfimationModal = () => {
    setConfirmationModal({
      isOpen: true,
      title: `Är du säker på att du vill skapa användaren?`,
      content: (
        <UserProfileSummary
          authenticationRights={authenticationRights}
          userProfile={{
            ...getValues<UserProfileCreateForm>(inputs),
            rights: userRights
          }}
        />
      ),
      confirmText: `Skapa ${inputs.firstName.value} ${inputs.lastName.value}`,
      rejectText: 'Tillbaka',
      rejectCallback: () => setConfirmationModal(null),
      confirmCallback: () => {
        createUserMutation.mutate({
          ...getValues<UserProfileCreateForm>(inputs),
          rights: userRights
        })
        setConfirmationModal(null)
      }
    })
  }

  return (
    <>
      <div className="Modal__Section FormModal__Row pb-2">
        <h5>{'Skapa en ny användare'}</h5>
      </div>
      <div className="Modal__Section FormModal__Row FormModal__Row--inputs">
        <Input
          className="Input--Full"
          error={
            inputs.personalNumber?.isError
              ? inputs.personalNumber.errorMessage
              : undefined
          }
          label="Personnummer *"
          name="personalNumber"
          onBlur={() => validateInput('personalNumber')}
          onChange={handleInputChange}
          required
          value={inputs.personalNumber.value}
        />
        <Input
          error={
            inputs.firstName?.isError
              ? inputs.firstName.errorMessage
              : undefined
          }
          label="Förnamn *"
          name="firstName"
          onBlur={() => validateInput('firstName')}
          onChange={handleInputChange}
          required
          value={inputs.firstName.value}
        />
        <Input
          error={
            inputs.lastName?.isError ? inputs.lastName.errorMessage : undefined
          }
          label="Efternamn *"
          name="lastName"
          onBlur={() => validateInput('lastName')}
          onChange={handleInputChange}
          required
          value={inputs.lastName.value}
        />
        <Input
          error={inputs.email?.isError ? inputs.email.errorMessage : undefined}
          label="E-mail *"
          name="email"
          onBlur={() => validateInput('email')}
          onChange={handleInputChange}
          required
          value={inputs.email.value}
        />
        <Input
          error={
            inputs.phoneNo?.isError ? inputs.phoneNo.errorMessage : undefined
          }
          label="Telefon *"
          name="phoneNo"
          onBlur={() => validateInput('phoneNo')}
          onChange={handleInputChange}
          required
          value={inputs.phoneNo.value}
        />
        <RightsSelection
          currentRights={userRights}
          onChange={setUserRights}
          style={{ marginTop: '2rem' }}
        />
      </div>
      <div className="FormModal__Actions Modal__Section">
        <Button
          className="FormModal__Button FormModal__Button--Cancel"
          onClick={() => closeModal()}
        >
          {'Stäng'}
        </Button>
        <Button
          className="FormModal__Button"
          disabled={!isValid || userRights.length === 0}
          isLoading={createUserMutation.isLoading}
          loadingText="Skapar användare"
          onClick={() => triggerConfimationModal()}
        >
          {'Skapa användare'}
        </Button>
      </div>
    </>
  )
}

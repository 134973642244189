import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useMeasure from 'react-use-measure'
import { useGetBankCards } from '../../hooks/queryHooks/useGetBankCards'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import { useIsMobile } from '../../hooks/useIsMobile'
import {
	cardSubMenuItems,
	createSideMenu,
	menuItems,
	profileSubMenuItems
} from '../../legacy/mock/components/menuItems'
import Banners from './Banners'
import Footer from './Footer'
import Header from './Header'
import Menu from './Menu'
import MobileMenu from './MobileMenu'
import SubMenu from './SubMenu'
import Toasts from './Toasts'

type Props = {
  children: React.ReactNode
}

const Page: React.FunctionComponent<Props> = ({ children }) => {
  const [headerRef, { height: headerHeight }] = useMeasure()
  const [bannersRef, { height: bannersHeight }] = useMeasure()

  const isMobile = useIsMobile()

  const { data: customer } = useGetCustomer()
  const { data: cards } = useGetCards()
  const { data: bankcards } = useGetBankCards()

  const headerItems = menuItems(cards!, bankcards!)
  const sideItems = createSideMenu({
    isCompany: customer!.isCompany,
    name: customer!.name
  })
  const cardSubHeaderItems = cardSubMenuItems(cards!, bankcards!)
  const profileSubHeaderItems = profileSubMenuItems()

  const location = useLocation()

  return (
    <>
      <Header ref={headerRef}>
        <Banners ref={bannersRef} />
        <Toasts headerHeight={headerHeight} />

        <>
          <Menu
            headerItems={headerItems}
            isCompany={customer!.isCompany}
            sideItems={sideItems}
          />
          <MobileMenu
            headerItems={headerItems}
            isCompany={customer!.isCompany}
            sideItems={sideItems}
          />
          {location.pathname.includes('/fakturor-kontobesked') && (
            <SubMenu headerItems={cardSubHeaderItems} transparent />
          )}
          {location.pathname.includes('/profil') && (
            <SubMenu headerItems={profileSubHeaderItems} transparent />
          )}
        </>
      </Header>

      <div
        className="MenuPusher"
        style={{
          /**
           * Push page down on mobile so that the banners don't cover important content
           */
          height: isMobile ? headerHeight : headerHeight - bannersHeight
        }}
      />
      <div className={
		// Adds page class to easier identify what is in the current view.
		classNames('PageContent', location.pathname?.slice(1).replace( '/', '-' ) )
		}>{children}</div>
      <Footer />
    </>
  )
}

export default Page

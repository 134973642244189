/* eslint-disable no-nested-ternary */
import React, { Fragment, useState } from 'react'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { currentUserHasRight } from '../../../utils/currentUserHasRight'
import { getPaymentMethodString } from '../../../utils/getPaymentMethodString'
import { isAutogiroEnabled as isAutogiroEnabledCheck } from '../../../utils/isAutogiroEnabled'
import Icon from '../../elements/Icon'
import RightsButton from '../../elements/RightsButton'
import { AutogiroModal } from './Components/AutogiroModal'
import { InvoiceMethodModal } from './Components/InvoiceMethodModal'
import ProfileBox from './ProfileBox'

type Props = {}

export type PaymentMethodModal = 'autogiro' | 'invoiceMethod' | 'none'

export const CustomerProfilePayment: React.FC<Props> = () => {
  const { data: customer } = useGetCustomer()

  const [openModal, setOpenModal] = useState<PaymentMethodModal>('none')

  const canWrite =
    currentUserHasRight('CustomerWrite') && currentUserHasRight('EmailWrite')

  const closeModal = () => {
    setOpenModal('none')
  }
  const changeThresholdDays = 10
  const minimumChangeDate = (() => {
    const date = new Date()
    date.setDate(date.getDate() - changeThresholdDays)
    return date
  })()

  const isPendingOrWithinThreshold = () => {
    const enabled = customer!.isAutogiroChangeRequestedByCustomer
    const changedDate = customer!.autogiroChangeRequestedByCustomerDate

    if (enabled) {
      // Pending is indicated by flag, no need to check the date
      return true
    }

    if (changedDate === null) {
      // No change date was passed. Trust the pending flag.
      return enabled
    }

    // Treat as pending if changed to false less than `changeThresholdDays` ago
    return new Date(changedDate) > minimumChangeDate
  }

  const isPending = isPendingOrWithinThreshold()

  const currentPaymentMethod = customer!.invoiceMailingDeliveryType ?? null
  // const isAutogiroEnabled =
  //   isEmpty(String(customer!.autogiroClearingNo)) &&
  //   isEmpty(String(customer!.autogiroAccountNo))

  const paymentMethodName = currentPaymentMethod
    ? getPaymentMethodString(
        currentPaymentMethod,
        isAutogiroEnabledCheck(customer!)
      ).long
    : null

  const invoiceEmails = customer!.emails.filter(
    (email) => email.emailTypeId === 'InvoiceStatement'
  )

  return (
    <>
      {openModal === 'invoiceMethod' && (
        <InvoiceMethodModal closeModal={closeModal} />
      )}
      {openModal === 'autogiro' && <AutogiroModal closeModal={closeModal} />}
      <h4 id="invoice-settings">{'Betalsätt - Tankkort'}</h4>
      <ProfileBox>
        <>
          <p>
            {'Du har valt '}
            <b>{paymentMethodName}</b>
            {'.'}
          </p>
          {currentPaymentMethod === 'PaperMailing' && (
            <>
              {isAutogiroEnabledCheck(customer!) && (
                <>
                  <div className="ProfileSettings__Warningbox">
                    <Icon
                      className="Warning__Icon"
                      name="exclamation-triangle"
                      primaryColor="yellow500"
                      secondaryColor="red400"
                    />
                    {
                      'En pappersfaktura skickas till dig varje månad till en kostnad av 25 kr.'
                    }
                  </div>
                  <br />
                </>
              )}
              {'Din faktura skickas till: '}
              <p className="BreakWord">
                <b>
                  {customer!.address1}
                  <br />
                  {customer!.postalCode}
                  {', '}
                  {customer!.city}
                </b>
              </p>
            </>
          )}
          {currentPaymentMethod === 'Email' && (
            <>
              {'Din faktura skickas till: '}
              <p className="BreakWord">
                <b>
                  {invoiceEmails
                    ? invoiceEmails.map((email) => (
                        <Fragment key={email.emailAddress}>
                          <span>{email.emailAddress}</span>
                          <br />
                        </Fragment>
                      ))
                    : 'Du har ingen mailadress inställd för mailfakturor'}
                </b>
              </p>
            </>
          )}
        </>
        <div className="PaymentOptions__Actions">
          <RightsButton
            className="PaymentOptions__Badge"
            disabled={customer!.isFrozen || !canWrite}
            onClick={() => setOpenModal('invoiceMethod')}
            type="button"
            userRights="CustomerWrite"
          >
            <span className="PaymentOptions__BadgeText">
              {'Redigera fakturasätt'}
            </span>
          </RightsButton>
          <RightsButton
            className="PaymentOptions__Badge"
            disabled={customer!.isFrozen || !canWrite}
            onClick={() => setOpenModal('autogiro')}
            type="button"
            userRights="CustomerWrite"
          >
            <span className="PaymentOptions__BadgeText">
              {isPending
                ? 'Mitt Autogiro'
                : isAutogiroEnabledCheck(customer!)
                ? 'Mitt Autogiro'
                : 'Ansök om Autogiro'}
            </span>
          </RightsButton>
        </div>
      </ProfileBox>
    </>
  )
}

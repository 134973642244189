/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import sv from 'date-fns/locale/sv'
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useGetBankCardTransactions } from '../../hooks/queryHooks/useGetBankCardTransactions'
import Label from '../elements/Label'
import Icon from './../elements/Icon'
import TransactionTable from './TransactionTable'
registerLocale('sv', sv)

type AllCardsHistoryProps = {
  startDate?: Date
  endDate?: Date
  setSelectedStartDate: (date: Date) => void
  setSelectedEndDate: (date: Date) => void
}

export interface BankCardSelectable {
  label: string
  value: string
}

export const AllBankCardsHistory: React.FC<AllCardsHistoryProps> = ({
  startDate,
  endDate,
  setSelectedStartDate,
  setSelectedEndDate
}) => {
  const balanceHistoryQuery = useGetBankCardTransactions({
    additionalQueryKeys: [endDate, startDate].filter(Boolean),
    fetchQuery: {
      startDate: startDate && startDate.toISOString(),
      endDate: endDate && endDate.toISOString()
    }
  })

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    balanceHistoryQuery

  return (
    <>
      <div className="CardsPage__FilterBar">
        <div className="Cardspage--FilterBarDiv">
          <Label text="Datum från:" />
          <div
            className={classNames(
              'CardsPage__FilterItem CardsPage__SearchBarContainer'
            )}
          >
            <DatePicker
              dateFormat={'yyyy-MM-dd'}
              locale="sv"
              selected={startDate}
              onChange={(date) => setSelectedStartDate(date!)}
            />
          </div>
        </div>
        <div className="Cardspage--FilterBarDiv">
          <Label text="Datum till:" />
          <div
            className={classNames(
              'CardsPage__FilterItem CardsPage__SearchBarContainer'
            )}
          >
            <DatePicker
              dateFormat={'yyyy-MM-dd'}
              locale="sv"
              selected={endDate}
              onChange={(date) => {
                setSelectedEndDate(date!)
              }}
            />
          </div>
        </div>
      </div>
      {isFetching && isFetchingNextPage === false ? (
        <div className="BankCardSpinner">
          <Icon
            className="UninvoicedBankcard__LoadingSpinner"
            fixedWidth
            name="spinner"
            spin
            type="line"
          />
        </div>
      ) : (
        <>
          {!isFetching && !data ? (
            <p>{'Något gick fel'}</p>
          ) : (
            <>
              {data!.pages.at(0)?.payload?.length === 0 && !hasNextPage ? (
                <p>{'Du har inga transaktioner.'}</p>
              ) : (
                <>
                  <TransactionTable
                    hasMore={hasNextPage}
                    isOdd
                    transactionsQuery={data!}
                    isBankCard={true}
                    magstripe=""
                  />
                  <div className="Uninvoiced__ActionContainer">
                    {hasNextPage && (
                      <button
                        className="Uninvoiced__LoadMoreButton"
                        onClick={() => fetchNextPage()}
                        type="button"
                      >
                        <Icon
                          className="Uninvoiced__ButtonIcon"
                          fixedWidth
                          name="plus"
                          type="line"
                        />
                        {isFetchingNextPage ? 'Laddar fler' : 'Visa fler'}
                        {isFetchingNextPage && (
                          <Icon
                            className="Uninvoiced__LoadingSpinner"
                            fixedWidth
                            name="spinner"
                            spin
                            type="line"
                          />
                        )}
                      </button>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { CustomTooltip } from './CustomTooltip'

type Props = {
  checked: boolean
  disabled?: boolean
  name: string
  label?: string
  onChange: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  helpText?: string
  infoText?: string
  style?: CSSProperties
}

const Toggle: React.FC<Props> = ({
  label,
  name,
  disabled,
  className,
  helpText,
  style,
  infoText,
  onChange,
  ...props
}) => {
  const changeHandler = disabled ? () => null : onChange
  return (
    <div
      className={classNames(
        'Toggle',
        { 'Toggle--Disabled': disabled },
        className
      )}
      style={style}
    >
      <input
        aria-disabled={disabled}
        id={`${name}_toggle`}
        name={name}
        onChange={changeHandler}
        type="checkbox"
        {...props}
      />
      <div className="Toggle__LabelRow">
        <label htmlFor={`${name}_toggle`}>{label}</label>
        {infoText && (
          <CustomTooltip text={infoText} tooltipPlacement="bottom" />
        )}
      </div>
      {helpText && <span className="Toggle__HelpText">{helpText}</span>}
    </div>
  )
}

export default Toggle

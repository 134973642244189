import React, {
  CSSProperties,
  Dispatch,
  FormEventHandler,
  SetStateAction
} from 'react'
import { useGetAuthenticationRights } from '../../hooks/queryHooks/useGetAuthenticationRights'
import {
  AuthenticationRightsGroup,
  AuthenticationRightsList
} from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import {
  AuthenticationRight,
  WriteAuthenticationRight,
  allAuthenticationRights,
  allReadAuthenticationRights
} from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import Button from '../elements/Button'
import Loader from '../elements/Loader'
import Toggle from '../elements/Toggle'

type RightsSelectionProps = {
  onChange:
    | Dispatch<SetStateAction<AuthenticationRight[]>>
    | ((newRights: AuthenticationRight[]) => void)
  currentRights: AuthenticationRight[]
  className?: string
  style?: CSSProperties
  disabled?: boolean
  shouldSuspend?: boolean
}

type FormattedRight = {
  name: AuthenticationRight
  description: string
}

type FormattedRightsList = Record<AuthenticationRightsGroup, FormattedRight>

export const formatRightsList = (
  rightsList:
    | AuthenticationRightsList<WriteAuthenticationRight>
    | AuthenticationRightsList
) => {
  const rightsWithoutSuperuser:
    | AuthenticationRightsList<WriteAuthenticationRight>
    | AuthenticationRightsList = rightsList.filter(
    // @ts-ignore
    (right) => right.claimName !== 'Superuser'
  )
  const formattedRights: Record<string, FormattedRight> = {}

  for (const right of rightsWithoutSuperuser) {
    if (
      !formattedRights[right.groupingName] &&
      right.claimName.includes('Write')
    ) {
      formattedRights[right.groupingName] = {
        name: right.claimName,
        description: right.description
      }
    }
  }

  return formattedRights as FormattedRightsList
}

const RightsSelection: React.FC<RightsSelectionProps> = ({
  onChange,
  currentRights,
  className = '',
  style,
  shouldSuspend = true,
  disabled
}) => {
  const { data: rightsList } = useGetAuthenticationRights({
    queryOptions: { suspense: shouldSuspend ?? true }
  })

  const formattedRights = rightsList && formatRightsList(rightsList)

  const userHasAllRights = currentRights.length + 1 === rightsList?.length

  const updateRights: FormEventHandler<HTMLInputElement> = ({
    currentTarget: { name }
  }) => {
    const rightName = name as AuthenticationRight
    const newRights = [...currentRights] as AuthenticationRight[]

    if (newRights.includes(rightName)) {
      newRights.splice(newRights.indexOf(rightName), 1)
    } else {
      newRights.push(rightName)
    }

    onChange(newRights)
  }

  const toggleAllRights = () => {
    if (userHasAllRights) {
      onChange([...allReadAuthenticationRights])
    } else {
      onChange([...allAuthenticationRights])
    }
  }
  return (
    <div className={`RightsSelection ${className}`} style={style}>
      <h5 className="RightsSelection__title">
        {'Redigeringsrättigheter'}{' '}
        {rightsList && (
          <Button
            className="RightsSelection__toggle-all-button"
            disabled={disabled}
            onClick={toggleAllRights}
          >
            {userHasAllRights
              ? 'Ta bort alla rättigheter'
              : 'Fyll i alla rättigheter'}
          </Button>
        )}
      </h5>
      <p className="RightsSelection__description">
        {
          'Välj nedan vilka typer av data användaren ska ha rättighet att redigera'
        }
      </p>
      {rightsList ? (
        <div className="RightsSelection__rights">
          {formattedRights &&
            Object.keys(formattedRights)
              .sort()
              .map((category) => {
                const rightCategory = category as AuthenticationRightsGroup
                const rightName = formattedRights[rightCategory].name
                const rightDescription =
                  formattedRights[rightCategory].description
                return (
                  <div
                    className="RightsSelection__rights-group"
                    key={rightCategory}
                  >
                    <Toggle
                      checked={currentRights.includes(rightName)}
                      disabled={disabled}
                      helpText={`Användaren kan${
                        currentRights.includes(rightName) ? ' ' : ' inte '
                      }redigera ${rightCategory.toLowerCase()}`}
                      infoText={rightDescription}
                      label={rightCategory}
                      name={rightName}
                      onChange={updateRights}
                    />
                  </div>
                )
              })}
        </div>
      ) : (
        <Loader loaderColor="#000000" noPadding text="Laddar Rättigheter..." />
      )}
    </div>
  )
}

export default RightsSelection

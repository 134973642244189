import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { MenuItem } from '../../legacy/mock/components/menuItems'
import Icon from './Icon'
import RightsButton from './RightsButton'
import Wrapper from './Wrapper'

const CLASS_NAME = 'HamburgerMenu'
const DISABLED_BUTTON_OPACITY = 0.5
const TOOLTIP_POSITION_X = 180
const TOOLTIP_POSITION_Y = 45

type Props = {
  align?: 'left' | 'right'
  items: MenuItem[]
}

export const HamburgerMenu: React.FC<Props> = ({ align, items }) => {
  const isBankIdUser = useIsBankIdUser()

  const hamburgermenuButton = useRef(null)
  const hamburgermenuIcon = useRef(null)

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const getDisabledButtonTooltipText = (needsBankId: boolean) => {
    if (needsBankId) {
      if (isBankIdUser) {
        return ''
      }

      return 'Logga in med BankID för att hantera ditt kort.'
    }

    return ''
  }

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      hamburgermenuButton.current !== event.target &&
      hamburgermenuIcon.current !== event.target
    ) {
      setExpanded(false)
    }
  }

  const handleClick = () => {
    setExpanded((oldState) => !oldState)
  }

  const handleItemClick = (onClick?: () => void) => {
    setExpanded(false)

    onClick?.()
  }

  const handleMouseMove = (event: MouseEvent) => {
    const x = event.clientX
    const y = event.clientY

    const elements = document.getElementsByClassName(
      'tooltipCards-span'
    ) as HTMLCollectionOf<HTMLElement>

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]!
      if (element.style) {
        element.style.left = x - TOOLTIP_POSITION_X + 'px'
        element.style.top = y - TOOLTIP_POSITION_Y + 'px'
      }
    }
  }

  return (
    <div className={CLASS_NAME}>
      <RightsButton
        aria-expanded={expanded}
        className={classNames({
          HamburgerMenu__Button: true,
          'HamburgerMenu__Button--Expanded': expanded
        })}
        onClick={handleClick}
        ref={hamburgermenuButton}
        tooltipPlacement="left"
        type="button"
        userRights="CardsWrite"
      >
        <Icon
          className="HamburgerMenu__Icon"
          name="ellipsis-h"
          primaryColor="black"
          ref={hamburgermenuIcon}
          secondaryColor="black"
        />
      </RightsButton>
      {expanded && (
        <ul
          className={classNames({
            HamburgerMenu__ItemList: true,
            'HamburgerMenu__ItemList--AlignRight': align === 'right'
          })}
        >
          {items.map((item) => {
            const disabledText = getDisabledButtonTooltipText(
              item.requiresBankId ?? false
            )
            const tooltipButtonClassName = classNames(
              'HamburgerMenu__ItemButton',
              disabledText && 'HamburgerMenu__ItemButton--Disabled'
            )
            const tooltipInnerStyle = {
              opacity: disabledText ? DISABLED_BUTTON_OPACITY : 1
            }
            const tooltipOnClick = disabledText
              ? undefined
              : () => handleItemClick(item.onClick)

            return (
              <li className="HamburgerMenu__Item" key={item.title}>
                <Wrapper
                  if={item.link && !disabledText}
                  // eslint-disable-next-line no-confusing-arrow
                  wrap={(children) =>
                    item.link ? <Link {...item.link}>{children}</Link> : <></>
                  }
                >
                  <div className="tooltipCards">
                    {disabledText && (
                      <span className="tooltipCards-span">{disabledText}</span>
                    )}
                    <button
                      className={tooltipButtonClassName}
                      onClick={tooltipOnClick}
                      type="button"
                    >
                      <div style={tooltipInnerStyle}>
                        {item.icon && (
                          <Icon
                            className="HamburgerMenu__ItemIcon"
                            {...item.icon}
                          />
                        )}
                        {item.title}
                      </div>
                    </button>
                  </div>
                </Wrapper>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

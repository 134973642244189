import { useAtom } from 'jotai'
import React from 'react'
import { previousRightsAtom, userRightsAtom } from '../../hooks/useUserRights'
import { AuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import Toggle from '../elements/Toggle'
import RightsSelection from '../views/RightsSelection'

type UserRightDevToolsProps = {}

const UserRightDevTools: React.FC<UserRightDevToolsProps> = (props) => {
  const [previousRights, setPreviousRights] = useAtom(previousRightsAtom)
  const [userRights, setUserRights] = useAtom(userRightsAtom)
  const currentRights =
    typeof userRights === 'string' ? previousRights : userRights
  const isSuperUser = userRights === 'Superuser'

  const toggleSuperUser = () => {
    if (userRights === 'Superuser') {
      setUserRights(previousRights)
      return
    }

    setPreviousRights(userRights)
    setUserRights('Superuser')
  }

  return (
    <div style={{ maxWidth: '40rem' }}>
      <div style={{ margin: '1rem 0 2rem 0' }}>
        <h5>{'Superuser'}</h5>
        <Toggle
          checked={isSuperUser}
          label={isSuperUser ? 'Du är superuser' : 'Du är inte superuser'}
          name="superuser-toggle"
          onChange={toggleSuperUser}
        />
      </div>
      <RightsSelection
        currentRights={currentRights}
        disabled={isSuperUser}
        onChange={(newRights: AuthenticationRight[]) =>
          setUserRights(newRights)
        }
        shouldSuspend={false}
      />
    </div>
  )
}

export default UserRightDevTools

import React from 'react'
import SmartCardView from '../views/SmartCard/SmartCardView'

type SmartCardPageProps = {}

const SmartCardPage: React.FC<SmartCardPageProps> = () => {
  return (
    <>
      <div className="Row">
        <div className="Row__Inner">
          <div className="Column Column--JustifyStart Column--Lg6">
            <div className="ContentBox">
              <div className="ContentBox__Content">
                <h1>{'Smartkort'}</h1>
                <p>
                  {
                    'Du måste ladda ditt kort innan det går att tanka och det tar upp till tre bankdagar innan pengarna registrerats och kan användas. Du laddar med ditt personliga OCR-nummer som alltid är samma. Brytdatum för kontobesked är alltid natten mellan den 15:e och den 16:e varje månad.'
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SmartCardView />
    </>
  )
}

export default SmartCardPage

import { forwardRef } from 'react'
import { Placement } from 'react-bootstrap/esm/types'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { useUserRights } from '../../hooks/useUserRights'
import { WriteAuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import Button, { ButtonProps } from './Button'
import { ToolTipWrapperProps } from './ToolTipWrapper'
import UserRightTooltip from './UserRightTooltip'

type RightsButtonProps = {
  userRights:
    | WriteAuthenticationRight
    | WriteAuthenticationRight[]
    | 'Superuser'
  tooltipPlacement?: Placement
  tooltipProps?: Partial<ToolTipWrapperProps>
  disableOnFrozenCustomer?: boolean
  disableOnPasswordLogin?: boolean
} & ButtonProps

const RightsButton = forwardRef<HTMLButtonElement, RightsButtonProps>(
  (
    {
      userRights,
      children,
      tooltipProps,
      disableOnFrozenCustomer = true,
      disableOnPasswordLogin = true,
      disabled,
      tooltipPlacement,
      ...props
    },
    ref
  ) => {
    const { userHasRight } = useUserRights()
    const { data: customer } = useGetCustomer()
    const isBankIdUser = useIsBankIdUser()
    const disableBecauseFrozen = customer!.isFrozen && disableOnFrozenCustomer
    const disableBecausePasswordLogin = !isBankIdUser && disableOnPasswordLogin

    const getPasswordOrFrozenText = () => {
      if (disableBecauseFrozen) {
        return 'Du kan inte använda denna funktion när ditt konto är spärrat'
      }

      return 'Logga in med BankID för att använda denna funktion'
    }

    return (
      <UserRightTooltip
        enabled={
          disableBecauseFrozen ||
          disableBecausePasswordLogin ||
          !userHasRight(userRights)
        }
        text={
          disableBecauseFrozen || disableBecausePasswordLogin
            ? getPasswordOrFrozenText()
            : undefined
        }
        tooltipPlacement={tooltipPlacement ?? tooltipProps?.tooltipPlacement}
        userRights={userRights}
        {...tooltipProps}
      >
        <Button
          aria-disabled={
            disabled ||
            disableBecauseFrozen ||
            disableBecausePasswordLogin ||
            !userHasRight(userRights)
          }
          ref={ref}
          {...props}
        >
          {children}
        </Button>
      </UserRightTooltip>
    )
  }
)
RightsButton.displayName = 'RightsButton'

export default RightsButton

import React from 'react'
import { redirect } from 'react-router-dom'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import NavTabs, { NavTabsObject } from '../elements/NavTabs'
import SuspenseLoaderWrapper from '../elements/SuspenseLoaderWrapper'
import BankCardPage from './BankCardPage'
import PaymentPage from './PaymentPage'
import SmartCardPage from './SmartCardPage'

type InvoiceAccountPageProps = {}

const InvoiceAccountPage: React.FC<InvoiceAccountPageProps> = () => {
  const { data: cards } = useGetCards()

  const tabs: NavTabsObject = {}

  if (cards!.tankCards.length === 0 && cards!.smartCards.length === 0) {
    redirect('/profil')
  }
  if (cards!.tankCards.length > 0) {
    tabs.tankkort = {
      label: 'Tankkort',
      component: (
        <SuspenseLoaderWrapper noPadding text="Laddar fakturor...">
          <PaymentPage />
        </SuspenseLoaderWrapper>
      )
    }
  }

  if (cards!.smartCards.length > 0) {
    tabs.smartkort = {
      label: 'Smartkort',
      component: (
        <SuspenseLoaderWrapper noPadding text="Laddar transaktioner...">
          <SmartCardPage />
        </SuspenseLoaderWrapper>
      )
    }
  }

  tabs.bankcard = {
    label: 'Bankkort',
    component: (
      <SuspenseLoaderWrapper noPadding text="Laddar transaktioner...">
        <BankCardPage />
      </SuspenseLoaderWrapper>
    )
  }

  return (
    <div>
      <div className="Section BgColor--Red400">
        <div className="Row Row--JustifyLeft" />
        <NavTabs tabs={tabs} />
      </div>
    </div>
  )
}

export default InvoiceAccountPage
